import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import axios from 'axios'
import './assets/styles/custom.scss';
import VueSwal from 'vue-swal';
import Paginate from 'vuejs-paginate'
import Multiselect from 'vue-multiselect'
import ReadMore from 'vue-read-more';


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('paginate', Paginate)
Vue.use(VueSwal);
Vue.component('multiselect', Multiselect)
Vue.use(ReadMore)


let baseUrl = "https://syndicate.staging.property-pistol.in/api/mobile_crm"
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://syndicate-network.in/api/mobile_crm"
}
axios.defaults.baseURL = baseUrl;
//axios interceptors
if (localStorage.getItem('is_public') != 'public') {
  axios.interceptors.request.use(function(config) {
    store.dispatch('setLoading', true);
    // console.log(JSON.stringify(config))
    if(config.url.indexOf('login') < 0 && config.url.indexOf('/forgot_password') < 0 && config.url.indexOf('/users/password')){
      let token = localStorage.getItem('empanelment-crm-token')
      if(token){
        config.headers.Authorization = `Token token=${token}`
      }else{
        return {
          headers: {},
          method: config.method,
          url: ""
        };
      }
    }
    let encrypted_key = localStorage.getItem('empanelment-crm-encrypted_key')
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    if (encrypted_key) {
      config.headers['skip-2fa-authentication'] = true
      config.headers['encrypted-key'] = encrypted_key
    }
    return config;
  }, function(err) {
    store.dispatch('setLoading', false);
    return Promise.reject(err);
  });
}

if (localStorage.getItem('is_public') != 'public') {
  axios.interceptors.response.use(function(response) {
    store.dispatch('setLoading', false);
    return response;
  }, function(error){
    if(error.response.status === 401){
      localStorage.removeItem('empanelment-crm-token');
      // eslint-disable-next-line no-undef
      swal({
        title: "Session Expired",
        text: "You have been redirected to the login page",
        icon: "error"
      })
      .then(router.replace('/login'),store.dispatch('setLoading', false));
    }else{
      store.dispatch('setLoading', false);
      return Promise.reject(error);
    }
  });
}

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('empanelment-crm-token')
  if(token && to.name === 'Login'){
    next({name: 'Home'});
  }else if(to.name !== 'Login' && to.name !== 'Invite' && to.name !== 'Thanks'  && to.name !== 'ForgotPassword' && to.name !== 'PasswordEdit' && !token){
    next({name: 'Login'});
  }else{
    if(to.name === 'Login' || to.name ==='Invite' || to.name === 'Thanks' || to.name ==='ForgotPassword' || to.name==="PasswordEdit"){
      store.dispatch('setLayout', 'login');
    }else{
      store.dispatch('setLayout', 'default');
    }
    next();
  }
})
Vue.filter('dateFormat', function (value) {
  if (!value) return '-'
  return moment(value).format('DD-MMM-YYYY')
})
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
